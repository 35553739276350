<template>

  <b-card>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="region">Filter By Region</label>
            <v-select
              id="region"
              v-model="selected.region"
              :options="list.regions"
              label="region_name"
              placeholder="-- Please Select Region --"
            >
              <template #option="{ region_name }">
                <span>{{ region_name }}</span>
              </template>
              <template #no-options="">
                No Available Region
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="province">Filter By province</label>
            <v-select
              id="province"
              v-model="selected.province"
              :options="list.provinces"
              label="province_name"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Province
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="has-chart">
            <h6>Gender</h6>
            <div v-if="checkAverageGender">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsGender"
                :series="seriesGender"
              />
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="has-chart">
            <h6>Field Size</h6>
            <div v-if="checkAverageFieldSize">
              <vue-apex-charts
                width="100%"
                height="200"
                type="donut"
                :options="optionsFieldSize"
                :series="seriesFieldSize"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="no-chart">
            <h6>Average Age</h6>
            <h2>
              {{ average_age }}
            </h2>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="no-chart">
            <h6>Average Farm Size (Hectare)</h6>
            <h2>
              {{ average_farm_size }}
            </h2>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-5"
        style="background: #eee"
      >
        <b-col
          cols="12"
          md="6"
          class="border-right"
        >
          <div class="no-chart">
            <h6 style="padding-left: 1rem">
              Average number of household
            </h6>
            <h2 v-if="checkAverageAge">
              {{ average_number_of_household }}
              <!-- <i class="ri-arrow-up-s-fill icon-carret" /> -->
            </h2>
            <!-- <div v-if="checkAverageAge">
                <p class="m-0">
                  member of the family
                </p>
              </div> -->
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xs="12"
        >
          <div class="no-chart">
            <h6>Average years in farming</h6>
            <h2 v-if="checkAverageAge">
              {{ average_years_of_farming }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>
<script>
import { core } from '@/config/pluginInit'
import { SharedStatisticsService, SharedListService } from '@/services'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'CustomerInformation',
  middleware: ['auth', 'manager', 'supervisor'],
  components: {
    VueApexCharts
  },
  data () {
    return {
      list: {
        regions: [],
        provinces: []
      },
      selected: {
        region: null,
        province: null
      },
      value: {
        region: '',
        province: ''
      },
      seriesGender: [],
      optionsGender: {
        series: [],
        labels: [],
        legend: {
          show: true
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },

      seriesFieldSize: [],
      optionsFieldSize: {
        series: [],
        labels: [],
        legend: {
          show: true
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Number(val).toFixed(2) + '%'
          }
        }
      },

      average_age: 0,
      average_farm_size: 0,
      genderTitle: '',
      fieldSizeTitle: '',
      average_number_of_household: 0,
      average_years_of_farming: 0
    }
  },

  computed: {
    checkAverageAge () {
      return this.average_age !== 0
    },

    checkAverageGender () {
      return this.seriesGender.length > 0
    },

    checkAverageFarmSize () {
      return this.average_farm_size !== 0
    },

    checkAverageFieldSize () {
      return this.seriesFieldSize.length > 0
    }
  },
  watch: {
    'selected.region' (value) {
      this.value.region = value?.id || null
      // this.value.province = null
      this.getProvinces(this.value.region)
      this.getStatistics()
    },
    'selected.province' (value) {
      this.value.province = value?.id || null
      this.getStatistics()
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
    this.getRegions()
  },

  methods: {
    async getStatistics () {
      const { data } = await SharedStatisticsService.getGraphCustomerInformation(
        `region=${this.value.region}&province=${this.value.province}`
      )
      this.average_age = data?.average_age?.average_age || 0
      this.average_farm_size = data?.average_number_of_hectare || 0
      this.seriesGender = data?.gender_total?.total_count
      this.optionsGender = {
        ...this.optionsGender,
        labels: data?.gender_total?.data
      }
      this.genderTitle = data?.gender_total?.data?.[0] || ''

      this.seriesFieldSize = data?.number_of_field_size?.total_count || []
      this.optionsFieldSize = {
        ...this.optionsFieldSize,
        labels: data?.number_of_field_size?.data
      }
      this.fieldSizeTitle = data?.number_of_field_size?.data?.[0] || ''

      this.average_number_of_household =
        data?.number_of_household?.number_of_household || 0
      this.average_years_of_farming = data?.years_of_farming?.average_year || 0
    },

    async getRegions () {
      await SharedListService.getRegions().then(({ data }) => {
        this.list.regions = data
      })
    },

    async getProvinces (regionId) {
      this.selected.province = null
      if (regionId) {
        await SharedListService.getProvinces(`region_id=${regionId}`).then(
          ({ data }) => {
            this.list.provinces = data
          }
        )
      } else {
        this.list.provinces = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.5rem;
  height: 100%;

  h6 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
  }
  .icon-carret {
    color: #28a745;
  }
  div {
    margin: 0 auto;
    p {
      background: #ff7a29;
      color: white;
      text-align: center;
      border-radius: 100px;
      padding: 0 3rem;
    }
  }
}

.has-chart {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  h6 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 4rem;
  }
  .title {
    margin: 0 auto;
    p {
      color: white;
      text-align: center;
      padding: 0 3rem;
      background: #ff7a29;
      border-radius: 100px;
    }
  }
}

.border-right {
  border-right: 1px solid rgb(181, 178, 178);
}
</style>
